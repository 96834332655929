const bahtxtConst = {
  defaultResult: 'ศูนย์บาทถ้วน',
  singleUnitStrs: ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'],
  placeNameStrs: ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']
}

const bahtxtNum2Word = (nums) => {
  let result = ''
  const len = nums.length
  const maxLen = 7

  if (len > maxLen) {
    const overflowIndex = len - maxLen + 1
    const overflowNums = nums.slice(0, overflowIndex)
    const remainingNumbs = nums.slice(overflowIndex)

    return `${bahtxtNum2Word(overflowNums)}ล้าน${bahtxtNum2Word(remainingNumbs)}`
  }

  for (let i = 0; i < len; i++) {
    const digit = nums[i]
    if (digit > 0) {
      result += bahtxtConst.singleUnitStrs[digit] + bahtxtConst.placeNameStrs[len - i - 1]
    }
  }

  return result
}

const bahtxtGrammarFix = (str) => str.replace(/หนึ่งสิบ/g, 'สิบ')
    .replace(/สองสิบ/g, 'ยี่สิบ')
    .replace(/สิบหนึ่ง/g, 'สิบเอ็ด')

const bahtxtCombine = (baht, satang) => {
  if (!baht && !satang) {
    return bahtxtConst.defaultResult
  }

  if (baht && !satang) {
    return `${baht}บาทถ้วน`
  }

  if (!baht && satang) {
    return `${satang}สตางค์`
  }

  return `${baht}บาท${satang}สตางค์`
}

const bahtText = (num) => {
  if (!num
    || typeof num === 'boolean'
    || Number.isNaN(Number(num))
    || num < Number.MIN_SAFE_INTEGER
    || num > Number.MAX_SAFE_INTEGER
  ) {
    return bahtxtConst.defaultResult
  }

  const positiveNum = Math.abs(num)

  const bahtStr = Math.floor(positiveNum).toString()
  const satangStr = ((positiveNum % 1) * 100).toFixed(0)

  const bahtArr = Array.from(bahtStr).map(Number)
  const satangArr = Array.from(satangStr).map(Number)

  let baht = bahtxtNum2Word(bahtArr)
  let satang = bahtxtNum2Word(satangArr)

  baht = bahtxtGrammarFix(baht)
  satang = bahtxtGrammarFix(satang)

  const result = bahtxtCombine(baht, satang)

  return num >= 0 ? result : `ลบ${result}`
}

export default bahtText

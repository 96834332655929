import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PurchaseOrdersProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('purchase-orders', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`purchase-orders/${id}`)
  }

  getItemByOrderId (id) {
    this.setHeader(getAuthToken())
    return this.get(`purchase-orders/${id}/code`)
  }

  createItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('purchase-orders', payload)
  }

  approveItem (id) {
    this.setHeader(getAuthToken())
    return this.put(`purchase-orders/${id}/approve`)
  }

  receiveItem (id) {
    this.setHeader(getAuthToken())
    return this.put(`purchase-orders/${id}/receive`)
  }

  cancelItem (id) {
    this.setHeader(getAuthToken())
    return this.put(`purchase-orders/${id}/cancel`)
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.put(`purchase-orders/${payload.id}`, payload)
  }

  updateExpectedReceiptDates (payload) {
    this.setHeader(getAuthToken())
    return this.put(`purchase-orders/${payload.id}/expected-receipt-dates`, payload)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`purchase-orders/${id}`)
  }

  getOverview (query) {
    this.setHeader(getAuthToken())
    return this.get('purchase-orders/overview', query)
  }

  getOverviewByOrderNo (orderNo) {
    this.setHeader(getAuthToken())
    return this.get(`purchase-orders/overview/${orderNo}`)
  }
}

export default PurchaseOrdersProvider

<template>
  <v-main>
    <div
      size="A4"
      layout="landscape"
      class="page-a4 container elevation-5">
      <v-row>
        <v-col cols="2">
          <img
            :src="gwLogo"
            alt="GW Logo"
            style="width: 100%; height: auto;"
            class="mt-2">
        </v-col>
        <v-col cols="10">
          <h3>
            บริษัท เจนเทิลวูแมน จำกัด (สำนักงานใหญ่)<br>
            GENTLEWOMAN COMPANY LIMITED (HEAD OFFICE)
          </h3>
          <v-divider
            class="my-2"
            style="border-color: #000000;" />
          <p style="font-size: 13px; margin: 0;">
            999/9 ห้องเลขที่ OFMH 3806-3812 ชั้นที่ 38 อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์ ถนนพระราม 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330
            <br>999/9 Room no. OFMH 3806-3812, 38th Floor, The Offices at Central World, Rama 1 Road, Pathumwan, Pathumwan, Bangkok 10330
            <br>เลขประจำตัวผู้เสียภาษี / Tax ID 0105561034561
          </p>
        </v-col>
        <v-col cols="12">
          <h2 class="text-center">
            Purchase Order<br>
            ใบสั่งซื้อ
          </h2>
        </v-col>
        <v-col cols="12">
          <div style="font-weight: bold; text-align: right;">
            ต้นฉบับ / Original
          </div>
          <v-row
            no-gutters
            style="border: 1px solid #000; font-size: 12px;">
            <v-col
              cols="6"
              class="px-1 table-content"
              style="border-right: 1px solid #000;">
              <div class="table-row">
                <div class="table-header">
                  รหัสผู้ขาย / Vendor Code
                </div>
                <span>
                  {{ formData.vendor.vendorNo }}
                </span>
                <div class="table-header">
                  ชื่อผู้ขาย / Vendor Name
                </div>
                <span>
                  {{ formData.vendor.name }}
                </span>
                <div class="table-header">
                  สาขา / Branch
                </div>
                <span>
                  {{ formData.vendor.branch }}
                </span>
                <div class="table-header">
                  ที่อยู่ / Address
                </div>
                <span>
                  {{ vendorAddress }}
                </span>
                <div class="table-header">
                  เลขประจำตัวผู้เสียภาษี / Tax ID
                </div>
                <span>
                  {{ formData.vendor.taxRegistrationNumber }}
                </span>
                <div class="table-header">
                  ผู้ติดต่อ / Contact Person
                </div>
                <span>
                  {{ formData.vendor.contactName }}
                </span>
                <div class="table-header">
                  เบอร์ติดต่อ / Tel
                </div>
                <span>
                  {{ formData.vendor.phoneNumber }}
                </span>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="px-1 table-content">
              <div class="table-row">
                <div class="table-header">
                  เลขที่ / No.
                </div>
                <span>
                  {{ formData.orderNo }}
                </span>
                <div class="table-header">
                  วันที่ / Date
                </div>
                <span>
                  {{ formData.documentDate | dateUserFormat() }}
                </span>
                <div class="table-header">
                  เลขที่อ้างอิง / Ref No.
                </div>
                <span>
                  {{ formData.orderNo }}
                </span>
                <!-- <div class="table-header">
                  เงื่อนไขการชำระเงิน / Credit Term
                </div>
                <span>
                  {{ formData.vendor.paymentTermCode }}
                </span> -->
                <div class="table-header">
                  สถานที่จัดส่ง / Ship Address
                </div>
                <span>
                  {{ `${formData.shipAddress}\n${formData.shipAddress2}` }}
                </span>
                <div class="table-header">
                  ผู้สั่งซื้อ / Purchaser
                </div>
                <span>
                  {{ formData.createdBy.email }}
                </span>
                <div class="table-header">
                  สกุลเงิน / Cur.
                </div>
                <span>
                  {{ formData.currencyCode || 'THB' }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <table
            border="1"
            class="item-table">
            <thead>
              <tr>
                <th style="width: 40px">
                  ลำดับ <br> No
                </th>
                <th style="width: 110px">
                  รหัสสินค้า <br> Item Code
                </th>
                <th>รายการ  <br>  Description</th>
                <th style="width: 80px">
                  วันที่ส่ง  <br>  Ship Date
                </th>
                <th style="width: 80px">
                  ราคาต่อหน่วย  <br> Unit Price
                </th>
                <th style="width: 70px">
                  ส่วนลด  <br> Discount
                </th>
                <th style="width: 60px">
                  จำนวน  <br>  Quantity
                </th>
                <th style="width: 70px">
                  จำนวนเงิน  <br> Amount
                </th>
              </tr>
              <!-- <tr>
                <th>No</th>
                <th>Item Code</th>
                <th>Description</th>
                <th>Ship Date</th>
                <th>Unit Price</th>
                <th>Discount</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr> -->
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in formData.purchaseOrderLines"
                :key="index"
                class="item-row">
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <!-- <td class="text-center">
                  {{ item.brand }}
                </td>
                <td
                  class="text-center"
                  style="width: 120px">
                  {{ item.gwCollection }}
                </td> -->
                <td class="text-center">
                  {{ item.itemNo }}
                </td>
                <td>
                  <span>
                    {{ item.description }}
                  </span>
                  <br>
                  <span class="text-capitalize">
                    {{ `${item.color} - ${item.size}` }}
                  </span>
                </td>
                <td class="text-center">
                  {{ item.expectedReceiptDate | dateUserFormat() }}
                </td>
                <td class="text-right">
                  {{ item.directUnitCost | showNumberFormat() }}
                </td>
                <td class="text-right">
                  {{ item.discount | showNumberFormat() }}
                </td>
                <td class="text-right">
                  {{ item.quantity | showNumberFormat() }} {{ item.uom }}
                </td>
                <td class="text-right">
                  {{ calLineAmount(item) | showNumberFormat() }}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div
                    class="d-flex flex-column justify-space-between"
                    style="min-height: 108px;">
                    <div
                      class="d-flex flex-column"
                      style="width: 100%; height: 100%;">
                      <span style="font-weight: bold;">
                        คอลเล็กชัน / Collection
                      </span>
                      <p>
                        {{ gwCollection }}
                      </p>
                      <span
                        style="font-weight: bold;">
                        หมายเหตุ / Note
                      </span>
                      <p>
                        {{ formData.remark }}
                      </p>
                    </div>
                    <span>
                      **{{ `${bathToText(afterVat)} / ${numToText(afterVat)}` }}
                    </span>
                  </div>
                </td>
                <td colspan="5">
                  <table style="width: 100%;">
                    <tr>
                      <td style="font-weight: bold;">
                        จำนวนรวม / Subtotal
                      </td>
                      <td class="text-right">
                        {{ totalPrice | showFullPriceFormat() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight: bold;">
                        <u>
                          หัก
                        </u>
                        <span>
                          ส่วนลด / Discount
                        </span>
                      </td>
                      <td class="text-right">
                        {{ totalDiscount | showFullPriceFormat() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight: bold;">
                        จำนวนก่อนภาษีมูลค่าเพิ่ม / Amount before VAT
                      </td>
                      <td class="text-right">
                        {{ beforeVat | showFullPriceFormat() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight: bold;">
                        ภาษีมูลค่าเพิ่ม / VAT ({{ vat }}%)
                      </td>
                      <td class="text-right">
                        {{ vatDiff | showFullPriceFormat() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight: bold;">
                        จำนวนรวมภาษีมูลค่าเพิ่ม / Amount after VAT
                      </td>
                      <td class="text-right">
                        {{ afterVat | showFullPriceFormat() }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="12">
          <div
            class="d-flex flex-row justify-space-between align-center px-5 mt-8"
            style="font-size: 13px;">
            <div class="d-flex flex-column align-center">
              <span style="font-weight: bold;">
                ผู้จัดทำ / Preparer
              </span>
              <div style="position: relative; width: 120px">
                <v-divider
                  class="mt-4 mb-2"
                  style="border-color: #000;" />
              </div>
              <div
                class="d-flex flex-row"
                style="position: relative;">
                <span style="font-weight: bold;">
                  วันที่ / Date _________
                </span>
              </div>
            </div>
            <div class="d-flex flex-column align-center">
              <span style="font-weight: bold;">
                ผู้อนุมัติ / Approver
              </span>
              <div style="position: relative; width: 120px">
                <v-divider
                  class="mt-4 mb-2"
                  style="border-color: #000;" />
              </div>
              <div
                class="d-flex flex-row"
                style="position: relative;">
                <span style="font-weight: bold;">
                  วันที่ / Date _________
                </span>
              </div>
            </div>
            <div class="d-flex flex-column align-center">
              <span style="font-weight: bold;">
                ผู้รับสินค้า / Receiver
              </span>
              <div style="position: relative; width: 120px">
                <v-divider
                  class="mt-4 mb-2"
                  style="border-color: #000;" />
              </div>
              <div
                class="d-flex flex-row"
                style="position: relative;">
                <span style="font-weight: bold;">
                  วันที่ / Date _________
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'
import { ToWords } from 'to-words'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import BahtText from '@/assets/js/BahtText'
import GWLogo from '@/assets/image/GTWM_Main_Logo-02.png'

const PurchaseOrdersService = new PurchaseOrdersProvider()

export default {
  data () {
    return {
      loading: false,
      vat: 7,
      formData: {
        documentDate: '',
        dueDate: '',
        vendor: {},
        yourReference: '',
        currencyCode: '',
        pricesIncludeTax: true,
        discountAmount: 0,
        noOfLines: 1,
        locationCode: '',
        shipAddress: '',
        shipAddress2: '',
        approver: '',
        receiver: '',
        approvedDate: '',
        receivedDate: '',
        note: '',
        purchaseOrderLines: [
          {
            sequence: 10000,
            lineType: '',
            itemNo: '',
            imageURL: '',
            description: '',
            description2: '',
            locationCode: '',
            quantity: 0,
            uom: '',
            directUnitCost: 0,
            discountAmount: 0,
            discountPercent: 0,
            ifStatus: '',
            groupCategory: '',
            size: '',
            color: '',
            categories: '',
            gwCollection: '',
            brand: '',
            expectedReceiptDate: ''
          }
        ],
        brand: '',
        createdBy: {
          id: 0,
          email: 'system'
        }
      },
      gwLogo: GWLogo
    }
  },
  computed: {
    totalPrice () {
      return this.formData.purchaseOrderLines.reduce((total, item) => total + (item.quantity * item.directUnitCost), 0)
    },
    totalDiscount () {
      return this.formData.purchaseOrderLines.reduce((total, item) => total + (item.quantity * item.discount), 0)
    },
    beforeVat () {
      if (this.formData.pricesIncludeTax) {
        return (this.totalPrice - this.totalDiscount) * (100 / 107)
      }
      return this.totalPrice - this.totalDiscount
    },
    gwCollection () {
      return [...new Set(this.formData.purchaseOrderLines.map((o) => o.gwCollection))].filter((o) => Boolean(o)).join(', ')
    },
    vatDiff () {
      return this.beforeVat * (this.vat / 100)
    },
    afterVat () {
      return this.beforeVat + this.vatDiff
    },
    vendorAddress () {
      return [
        `${this.formData.vendor.address} ${this.formData.vendor.address2}`,
        this.formData.vendor.city,
        this.formData.vendor.state,
        this.formData.vendor.postalCode
      ].filter((v) => !!v).join(', ')
    }
  },
  mounted () {
    this.getItem()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    calLineAmount (item) {
      const unitCost = item.directUnitCost - item.discount
      return unitCost > 0 ? unitCost * item.quantity : 0
    },
    async getItem () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await PurchaseOrdersService.getItemById(this.$route.params.id)

        this.formData = {
          ...data,
          purchaseOrderLines: data.purchaseOrderLines.map((line) => ({
            ...line,
            discount: line.directUnitCost * (line.discountPercent / 100)
          }))
        }
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    bathToText (num) {
      return BahtText(num)
    },
    numToText (num) {
      const toWords = new ToWords()

      return `${toWords.convert(num)} Baht`
    }
  }
}
</script>

<style lang="scss" scoped>
div[size="A4"] {
  display: block;
  width: 210mm;
  min-height: 297mm;
  margin: 10px auto;
  // padding: calc(12.7mm - 24px);
  background-color: #ffffff;
  word-wrap: break-word;
  page-break-after: always;
  font-family: 'Times New Roman', Times, serif;
  .col {
    position: relative;
  }
}
.page-break {
  page-break-before: always;
}
.page-break-after {
  display: none;
}
.item-table {
  font-size: 14px;
  border-collapse: collapse;
  border-color: #000;
  width: 100%;
}
.item-table th {
  padding: 2px 0px;
  font-size: 12px;
}
.item-table td {
  font-size: 10px;
  padding: 2px 4px;
}
.item-table .item-row td {
  border-top-style: hidden;
  vertical-align: top;
}
.item-table .item-row:first-child td {
  border-top-style: solid;
}
.table-content {
  display: flex;
  flex-direction: column;
}
.table-content .table-row {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 16px;
}
.table-content .table-row .table-header {
  font-weight: bold;
  text-wrap: nowrap;
}
@media print {
  .page-break {
    page-break-before: avoid;
    page-break-inside: avoid;
  }
  .page-break-after {
    display: block;
    page-break-after: always;
  }
}
</style>
